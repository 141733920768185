import axios from "axios";
import { toast } from "react-toastify";


const sendMailHandler = async (text, subject, setbuttonStatus) => {
    try {
        setbuttonStatus("Please Wait!");
        const data = { text, subject }
        await axios.post("https://hypethegymbackend.vercel.app/send-email", data);
        toast.success("Thanks you for your inquiry,One of our team members will contact you shortly.");
        setbuttonStatus("");

    } catch (error) {
        setbuttonStatus("");
        console.log("Error Occred while sending the mail ", error)
    }
}

export default sendMailHandler;



// "aboutus create reamove review"