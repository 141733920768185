import React from 'react'
import Carousal from '../carousal/Carousal';


const OurProgram = () => {
    return (
        <section id='gallery'>
            <div className='my_container pt-28 sm:pt-36 md:pt-44 xl:pt-56 pb-7'   >
                <h2 className='capitalize text-4xl text-center yanoneFont' >our gallery</h2>
                <p className='text-center textGray my-3'>Explore Our World: Step inside the vibrant world of Hype The Gym. Get a sneak peek of our state-of-the-art facilities,<br /> motivating atmosphere, and the energy that fuels your fitness journey. Swipe through our gallery  <br />and see why we're the ultimate destination for fitness enthusiasts.</p>
            </div>
            <Carousal />
        </section>
    )
}

export default OurProgram



