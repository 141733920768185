import React from 'react';
import { MdSportsGymnastics } from 'react-icons/md';
import { TbYoga, TbStretching2, TbSteam } from 'react-icons/tb';
import { BiDumbbell } from 'react-icons/bi';
import { GiWeightLiftingUp } from 'react-icons/gi';

const OurServices = () => {
    return (
        <section id='ourservices'>
            <div className='my_container pt-5 sm:pt-14 md:pt-28 pb-7' >
                <h2 className='capitalize text-4xl md:text-5xl lg:text-[56px] text-center yanoneFont'>Our Services</h2>
                <p className='text-center textGray mt-5 md:mt-7 max-w-4xl mx-auto'>Discover Our Services: At Hype The Gym, we offer a diverse range of fitness services to cater to your unique goals and interests. Whether you're into the zen of yoga, the rhythm of Zumba, or the intensity of a full-body workout, we have something for everyone. Explore our services below and choose the path to your fitness success.</p>
                <div className='pb-8 pt-10 sm:py-14 lg:pt-20 xl:pt-24 flex flex-wrap justify-between lg:justify-center'>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-none  my-3'>
                        <span className='textYellowGreen text-5xl'><BiDumbbell /></span>
                        <span className='yanoneFont text-2xl my-2'>Gym & Cardio</span>
                        <p className='textLightGray text-center '>Our gym offers the perfect blend of strength and stamina training. Achieve your fitness goals with our well-equipped gym, while also boosting your cardiovascular health through our dynamic cardio workouts.</p>
                    </div>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-y-0  my-3'>
                        <span className='textYellowGreen text-5xl'><GiWeightLiftingUp /></span>
                        <span className='yanoneFont text-2xl my-2'>Weight Training & Weight Loss</span>
                        <p className='textLightGray text-center '>Sculpt your body and shed those extra pounds with our weight training programs. Tailored workouts designed for effective weight loss will help you transform your physique.</p>
                    </div>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-y-0 lg:border-x-0 my-3'>
                        <span className='textYellowGreen text-5xl'><MdSportsGymnastics /></span>
                        <span className='yanoneFont text-2xl my-2'>Boxing & Yoga</span>
                        <p className='textLightGray text-center '>Discover the perfect balance between power and inner peace. Our boxing and yoga sessions will not only improve your physical strength but also provide mental tranquility.</p>
                    </div>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-x-0 lg:border-b-0  my-3'>
                        <span className='textYellowGreen text-5xl'><TbStretching2 /></span>
                        <span className='yanoneFont text-2xl my-2'>Bollyhype & Bhangra</span>
                        <p className='textLightGray text-center '>Get your groove on with our energetic Bollyhype classes, or embrace the spirit of celebration with our vibrant Bhangra sessions. Dance your way to fitness and happiness.</p>
                    </div>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-b-0  my-3'>
                        <span className='textYellowGreen text-5xl'><TbYoga /></span>
                        <span className='yanoneFont text-2xl my-2'>Bollywood Dance & Power Yoga</span>
                        <p className='textLightGray text-center '>Experience the fusion of high-energy Bollywood dance and the tranquility of power yoga. These classes will boost your stamina, flexibility, and positivity.</p>
                    </div>
                    <div className='flex flex-col items-center yellowGreenBorder border-2  rounded-md px-4 py-5 lg:py-8 lg:px-5 max-w-sm lg:max-w-xs xl:max-w-[360px] mx-auto lg:mx-0 lg:my-0 lg:rounded-none lg:border-x-0 lg:border-b-0 my-3'>
                        <span className='textYellowGreen text-5xl'><TbSteam /></span>
                        <span className='yanoneFont text-2xl my-2'>Aerobics & Steam</span>
                        <p className='textLightGray text-center '>Ignite your metabolism with invigorating aerobics classes, then rejuvenate in our soothing steam room. It's the ideal combination for a well-rounded fitness experience.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurServices