import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer';
import heroImageLocation from '../../images/ourprogams/image10.webp'
import CountUp from 'react-countup';
import { AiFillClockCircle } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import { HiLocationMarker } from 'react-icons/hi';
import sendMailHandler from '../common/sendMail';

const Location = ({ toggleContactForm }) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    const gurgaonBranches = ["Sector 7", "Sector 9a ", "Sector 10a", "Sector 12", "Sector 14", "Sector 15", "Sector 31", "Sector 37c", "Sector 39", "Sohna Road"];
    const fridabadBranches = ["Sector 28 ", "Sector 37 ", "Sector 46 ", "Sector 79 ", "Nit5 "];
    const otherBranches = ["Tricity Sector 16 pkl", " Tricity Sector 20 pkl ", "Kharar", "Sector 23 dwarka"];
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        phone: '',
    });

    const inputHandler = (e) => {
        const newValue = e.target.value.replace(/\s+/g, " ");
        setFormData({ ...formData, [e.target.name]: newValue });
    }

    const [buttonStatus, setbuttonStatus] = useState("");
    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `New Message from ${formData.firstName}  ${formData.lastName}

Dear Aseem Yadav,
I hope this message finds you well. I wanted to share a message we received from a potential customer interested in our gym. The message is as follows:
First Name: ${formData.firstName} 
Last Name:${formData.lastName}
Phone Number: ${formData.phone}

This individual is interested in our gym and may have inquiries or feedback. We recommend reaching out to them promptly to assist with their needs and provide any information they may require.
     
Best regards,
${formData.firstName}  ${formData.lastName}
    
${formData.phone}
        `
        const userSubject = `New Appointment/Franchise Inquiry from ${formData.firstName} ${formData.lastName}`;
        sendMailHandler(message, userSubject, setbuttonStatus)
        setFormData({
            firstName: '',
            lastName: '',
            phone: '',
        })
    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <section>
            <div className='my_container'>
                {/* <Navbar toggleContactForm={toggleContactForm} /> */}
                <div className='mt-12 py-12 md:flex justify-between items-center lg:items-stretch'>
                    <div className='py-7 px-5 max-w-md lg:max-w-lg md:ml-0 md:mx-0 md:mr-5 w-full    mx-auto border rounded-lg '>
                        <div className='text-center'>
                            <h2 className='capitalize text-4xl   yanoneFont textYellowGreen'>Elevate Your  <span className='textWhite'>Fitness Journey</span></h2>
                            <p>Please Fill Out the Form Below to Get Started</p>
                        </div>
                        <form onSubmit={handleSubmit}>
                            <div className='mt-5'>
                                <div className='md:flex  '>
                                    <input value={formData.firstName} required onChange={inputHandler} pattern="[a-zA-Z ]+" minLength={4} className='w-full lg:w- outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg md:mr-3' name='firstName' type="text" placeholder='First Name' />
                                    <input value={formData.lastName} required onChange={inputHandler} name='lastName' pattern="[a-zA-Z ]+" minLength={4} className='w-full lg:w- outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg' type="text" placeholder='Last Name' />
                                </div>
                                <input value={formData.phone} required onChange={inputHandler} name='phone' type="text" className='w-full   outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg' placeholder='Your Mobile Number' pattern="[0-9]+" maxLength={10} minLength={10} />
                                <button className='py-3 mt-5 w-full bgYellowGreen textWhite rounded-lg disabled:bg-red-500/50' disabled={buttonStatus}>{buttonStatus ? buttonStatus : "Submit Now"}</button>
                            </div>
                        </form>

                    </div>
                    <div className='max-w-sm sm:max-w-md md:max-w-sm lg:max-w-lg mx-auto md:mx-0 mt-10 md:mt-0 rounded-lg'>
                        <img className='w-full rounded-lg' src={heroImageLocation} alt="heromiage" />
                    </div>
                </div>
                <div className='bgYellowGreen rounded-lg py-2 mb-7'>
                    <div className='flex items-center justify-center  font-bold text-2xl md:text-3xl xl:text-4xl'>
                        <p className=''><CountUp end={60} start={0} delay={1} duration={8} />+</p>
                        <p className='text-lg pt-1 ml-1'>Branches</p>
                    </div>
                    <p className='text-center text-lg font-semibold capitalize'>hype premium gym near you</p>
                </div>
                <div>
                    <div className='flex flex-wrap items-start justify-between'>
                        {gurgaonBranches.map((item, index) => {
                            return <div className='rounded-lg border borderGray my-7 sm:my-10 w-full max-w-lg mx-auto xl:mx-0' key={index + 23}>
                                <ul>
                                    <li className='cardLightDarkBg flex items-center   p-3 rounded-t-lg  border-b borderGray '><HiLocationMarker className='mr-3' /> HYPE THE GYM  {item}, Gurgaon</li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><BsFillTelephoneFill className='mr-3' />+91-9999501352, +91-9555200004</li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><AiFillClockCircle className='mr-3' />06:00 AM to 10:00 PM</li>
                                    <li className='cardLightDarkBg   items-center px-2 py-4 rounded-b-lg  border-b borderGray flex justify-center'>
                                        <a href='tel:+919555200004' className='bgYellowGreen py-2 px-7 rounded-lg cursor-pointer'>Request A Call</a>
                                    </li>
                                </ul>
                            </div>
                        })}
                        {fridabadBranches.map((item, index) => {
                            return <div className='rounded-lg border borderGray my-7 sm:my-10 w-full max-w-lg mx-auto xl:mx-0' key={index + 23}>
                                <ul>
                                    <li className='cardLightDarkBg flex items-center   p-3 rounded-t-lg  border-b borderGray '><HiLocationMarker className='mr-3' /> HYPE THE GYM  {item}, Faridabad </li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><BsFillTelephoneFill className='mr-3' />+91-9999501352, +91-9555200004</li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><AiFillClockCircle className='mr-3' />06:00 AM to 10:00 PM</li>
                                    <li className='cardLightDarkBg   items-center px-2 py-4 rounded-b-lg  border-b borderGray flex justify-center'>
                                        <a href='tel:+919555200004' className='bgYellowGreen py-2 px-7 rounded-lg cursor-pointer'>Request A Call</a>
                                    </li>
                                </ul>
                            </div>
                        })}
                        {otherBranches.map((item, index) => {
                            return <div className='rounded-lg border borderGray my-7 sm:my-10 w-full max-w-lg mx-auto xl:mx-0' key={index + 23}>
                                <ul>
                                    <li className='cardLightDarkBg flex items-center   p-3 rounded-t-lg  border-b borderGray '><HiLocationMarker className='mr-3' /> HYPE THE GYM  {item}</li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><BsFillTelephoneFill className='mr-3' />+91-9999501352, +91-9555200004</li>
                                    <li className=' flex items-center justify-between p-3 rounded-t-lg  border-b borderGray '><AiFillClockCircle className='mr-3' />06:00 AM to 10:00 PM</li>
                                    <li className='cardLightDarkBg   items-center px-2 py-4 rounded-b-lg  border-b borderGray flex justify-center'>
                                        <a href='tel:+919555200004' className='bgYellowGreen py-2 px-7 rounded-lg cursor-pointer'>Request A Call</a>
                                    </li>
                                </ul>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default Location