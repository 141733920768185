import React, { useRef, useState } from 'react';
import heroImage from '../../images/joinusimage.webp';

const JoinCommunitySection = ({ toggleContactForm }) => {
    const emailRef = useRef();
    const [email, setEmail] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);

    const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (validateEmail(email)) {
            // Email is valid, you can submit the form or perform other actions here
            setIsValidEmail(true);
            toggleContactForm()
            // Add your form submission logic here
        } else {
            emailRef.current.focus();
            setIsValidEmail(false);
        }
    };
    return (
        <section className='overflow-hidden lg:pt-24 xl:pt-44'>
            <div className='my_container  my-10   '>
                <div className=' py-10 px-5  bgYellowGreen rounded-xl textWhite'>
                    <div className='lg:flex justify-between'>
                        <div className='hidden lg:block   relative h-60 w-10  '>
                            <div className='absolute -left-16 -bottom-12 xl:-left-32 xl:-bottom-[75px]  w-[600px] xl:w-[766px]    '>
                                <img src={heroImage} className='w-full' alt="" />
                            </div>
                        </div>
                        <div className='max-w-xl mx-auto lg:mx-0 lg:max-w-xl z-30 '>
                            <h3 className='uppercase text-4xl md:text-5xl lg:text-6xl xl:text-7xl  text-center lg:text-start yanoneFont  '>lets join our community</h3>
                            {/* <p>Join Our Thriving Fitness Community</p> */}
                            <p className='mt-[10px] text-justify'>Join the Hype The Gym community to receive exclusive updates, fitness tips, and special offers directly to your inbox. By sharing your email with us, you'll become an integral part of our vibrant fitness-driven family, supporting you in every step of your journey.  Join us now and let's embark on this exciting journey together</p>
                            <form onSubmit={handleSubmit}>
                                <div className='mt-7 xl:mt-10 h-14 relative   text-center flex items-center justify-center rounded-xl'>
                                    <input type="email"
                                        ref={emailRef}
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)} required className='sm:w-full rounded-e-none rounded-s-lg py-2 sm:py-4 sm:px-8 px-3 outline-none text-lg md:text-xl text-black' placeholder='Email' />
                                    {!isValidEmail && <p className='absolute -bottom-6 left-0 text-black'>Please enter a valid email address.</p>}
                                    <button type='submit' className='font-bold rounded-e-lg px-5 py-2 sm:py-4 sm:px-8 text-lg md:text-xl bg-black text-white'>Join</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JoinCommunitySection