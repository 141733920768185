import React from 'react'
import Footer from '../common/Footer';
import ownerImage from '../../images/ownerimagecrop.jpg';



const AboutUs = ({ toggleContactForm }) => {



    return (
        <section>
            <div className="aboutusHeroSection mt-20 md:mt-24 xl:mt-28">
                <div className='bg-black/60'>
                    <div className='my_container '>
                        <div className='  h-64 sm:h-72 xl:h-80 flex flex-col justify-center items-center'>
                            <h2 className='yanoneFont textYellowGreen text-3xl sm:text-4xl md:text-5xl xl:text-6xl'>About Us</h2>
                            <p className='text-white mt-3 text-center max-w-2xl'>Discover the passion and dedication behind HYPE THE GYM, and how we've evolved into a renowned brand, redefining fitness with top-notch facilities and personalized training. Join us on this inspiring journey towards a healthier, fitter, and more successful future.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='my_container py-5 md:py-7'>
                    <div className='md:flex flex-row-reverse items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-sm xl:max-w-lg   mx-auto md:mx-0  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={'https://images.unsplash.com/photo-1571902943202-507ec2618e8f?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8Nnx8Z3ltfGVufDB8fDB8fHww'} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-sm xl:max-w-md   '   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'>Our  </span> Story</h2>
                            <p className='mt-5 textLightGray'>HYPE THE GYM was founded by Aseem Yadav, a software engineer with a dream. With years of experience and a burning desire to promote fitness, Aseem embarked on a journey to open gyms that would redefine the industry. Since its inception, HYPE THE GYM has evolved into a renowned brand, known for its exceptional facilities, personalized training, and the legacy of excellence set by Aseem.</p>
                        </div>
                    </div>
                    <div className='md:flex items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-sm xl:max-w-lg   mx-auto md:mx-0  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={"https://images.unsplash.com/photo-1583454110551-21f2fa2afe61?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8ODZ8fGd5bXxlbnwwfHwwfHx8MA%3D%3D"} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-sm xl:max-w-md'   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'> Our </span> Mission</h2>
                            <p className='mt-5 textLightGray'>Our mission at HYPE THE GYM is to create a community of empowered and healthy individuals. We are dedicated to offering personalized training, cutting-edge resources, and a welcoming environment. Through our passion for fitness and commitment to excellence, we aim to inspire and support our members in their journey to a healthier, fitter life.</p>
                        </div>
                    </div>
                    <div className='md:flex flex-row-reverse items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-sm xl:max-w-lg   mx-auto md:mx-0  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={"https://images.unsplash.com/photo-1579364046732-c21c2177730d?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTI4fHxneW18ZW58MHx8MHx8fDA%3D"} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-sm xl:max-w-md'   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'> Our </span> Vision</h2>
                            <p className='mt-5 textLightGray'>HYPE THE GYM envisions a world where everyone embraces a fit and healthy lifestyle. Our vision is to empower individuals to achieve their fitness goals, providing them with top-notch facilities and expert guidance. We aspire to be the catalyst for a global fitness revolution, making wellness a way of life.</p>
                        </div>
                    </div>
                    <div className='md:flex items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-xs   mx-auto  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={ownerImage} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-md lg:max-w-2xl xl:max-w-2xl 2xl:max-w-3xl'   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'>Meet Our </span> Visionary Leader</h2>
                            <p className='mt-5 textLightGray'><span className='font-bold'>Aseem Yadav</span>, a dedicated software engineer, envisioned a healthier world through gyms and personalized fitness training. Leading the success story of HYPE THE GYM, he's a fervent advocate for health and fitness. With years of experience and an unwavering commitment to excellence, Aseem has set the fitness industry standard. Their exceptional facilities, customized training, and inclusive community have elevated HYPE THE GYM to a renowned brand. Join us in this remarkable journey led by inspiring leadership, towards a healthier, fitter, and more prosperous future.</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </section>
    )
}

export default AboutUs