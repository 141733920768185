import React from 'react';
import './home.css';
import heroImage from '../../images/herolargeimage.webp';
import heroLargeImage from '../../images/herolargeimage.webp'
import CountUp from 'react-countup';

const HeroSection = ({ toggleContactForm }) => {

    return (
        <>
            <section className='mt-12' id='home'>
                <div className='my_container py-10'>
                    <div className='yanoneFont font-bold -mx-3 text-5xl sm:text-7xl   md:text-8xl lg:text-9xl xl:text-[155px] text-center  sm:mt-5'>
                        <h1 >ELEVATE YOUR WORKOUT</h1>
                        <div className='heroHeading -mt-[22px] -z-10 sm:-mt-8 md:-mt-11 lg:-mt-[58px] xl:-mt-[73px]'>ELEVATE YOUR WORKOUT</div>
                    </div>
                    <div className='lg:hidden relative mt-10 max-w-sm mx-auto  '>
                        <img src={heroImage} className='w-full' alt="heroImage" />
                    </div>
                    <div className='sm:pt-5 lg:flex justify-between items-end relative'>
                        <div className='mt-10 z-10 text-center lg:text-start lg:max-w-xs  '>
                            <p className='text-sm '>Welcome to Hype The Gym, where fitness meets excitement! Join us on a journey to transform your body and elevate your well-being. It's time to unleash your potential. Ready to get started? Click 'Join Now' and let's begin your fitness adventure!</p>
                            <button onClick={toggleContactForm} className='mt-10 bgYellowGreen px-6 py-2 textWhite font-bold rounded-md hover:scale-105 duration-300'>Join Now</button>
                        </div>
                        <div className='hidden   lg:flex justify-center absolute w-full h-full left-0 top-0'>
                            <div className='   relative mt-10 max-w-sm  xl:-translate-y-20 lg:max-w-xl xl:max-w-3xl mx-auto  '>

                                <img src={heroLargeImage} className='w-full -z-10' alt="heroImage" />
                            </div>
                        </div>
                        <div className='mt-10 '>
                            <div className='flex lg:flex-col justify-between max-w-xs mx-auto'>
                                <div className='yanoneFont  my-3'>
                                    <p className='font-bold text-2xl md:text-3xl xl:text-4xl'><CountUp end={300} start={0} delay={1} duration={8} />K+</p>
                                    <p className='text-lg'>Members</p>
                                </div>
                                <div className='yanoneFont  my-3'>
                                    <p className='font-bold text-2xl md:text-3xl xl:text-4xl'><CountUp end={600} start={0} delay={1} duration={8} />+</p>
                                    <p className='text-lg'>Trainers</p>
                                </div>
                                <div className='yanoneFont  my-3'>
                                    <p className='font-bold text-2xl md:text-3xl xl:text-4xl'><CountUp end={60} start={0} delay={1} duration={8} />+</p>
                                    <p className='text-lg'>Branches</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HeroSection