import React, { useEffect, useState } from 'react'
import Footer from '../common/Footer';
import sendMailHandler from '../common/sendMail';


const Franchise = ({ toggleContactForm }) => {
    const [buttonStatus, setbuttonStatus] = useState("");
    const paraData = [{ heading: "PARTNER WITH INDIA'S FASTEST EMERGING gYM BRAND", text: "Partner with India's Fastest Emerging Gym Brand and embark on a journey of growth and prosperity. HYPE THE GYM offers an exceptional franchise opportunity, providing access to a well-established brand, cutting-edge fitness solutions, and a proven business model. Join us and be a part of the fitness revolution, unlocking endless possibilities for success and impact in the health and wellness industry." }, { heading: "GYM FRANCHISE BENEFITS FOR YOU", text: "Our Gym Franchise Benefits are designed to ensure your success. By partnering with HYPE THE GYM, you gain access to our renowned brand, extensive support, and a proven business model. Enjoy low startup costs, marketing assistance, and ongoing training. Be part of a dynamic community, offering a wide range of fitness services, and tap into the thriving health and wellness market. Maximize your entrepreneurial potential with HYPE THE GYM." }];
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: "",
    });

    const inputHandler = (e) => {
        const newValue = e.target.value.replace(/\s+/g, " ");
        setFormData({ ...formData, [e.target.name]: newValue });
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const message = `Dear HYPE THE GYM Team,
        I hope this message finds you well. I am writing to express my interest in scheduling an appointment to discuss the possibility of buying a franchise of HYPE THE GYM.

        Here are my contact details:

        First Name: ${formData.firstName} 
        Last Name:${formData.lastName}
        Email: ${formData.email}
        Phone Number: ${formData.phone}

       ${formData.message ? formData.message : " I am enthusiastic about the prospect of joining your esteemed fitness community. I look forward to the opportunity to learn more about the franchise options, services, and benefits that HYPE THE GYM offers. Please let me know a convenient time for our meeting. I appreciate your time and consideration."}

       Best regards,
       ${formData.firstName}  ${formData.lastName}
       ${formData.email}
        ${formData.phone}
        `
        const userSubject = `New Appointment/Franchise Inquiry from ${formData.firstName} ${formData.lastName}`;
        sendMailHandler(message, userSubject, setbuttonStatus)
        setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            message: "",
        })

    };

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <>
            <section>
                {/* <Navbar toggleContactForm={toggleContactForm} /> */}
                <div className="franchiseHeroSection mt-20 md:mt-24 xl:mt-28">
                    <div className='bg-black/60'>
                        <div className='my_container '>
                            <div className='max-w-2xl h-64 sm:h-72 xl:h-80 flex flex-col justify-center '>
                                <h2 className='yanoneFont textYellowGreen text-3xl sm:text-4xl md:text-5xl'>Franchise</h2>
                                <p className='text-white mt-3'>Join the HYPE THE GYM family and be a part of our success story. We offer franchise opportunities that empower you to bring our world-class fitness experience to your community. Explore the journey of becoming a part of HYPE THE GYM today.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my_container py-5 md:py-7'>
                    <div className=' text-center'>
                        <h3 className='textLightGray  capitalize'>Schedule a Meeting with Our Franchise Team</h3>
                        <h3 className='yanoneFont   text-3xl sm:text-4xl md:text-5xl xl:text-6xl uppercase my-5 md:my-6'>Book Your Appointment</h3>
                        <form onSubmit={handleSubmit}>
                            <div className='max-w-3xl mx-auto'>
                                <div className='sm:flex sm:space-x-5'>
                                    <input value={formData.firstName} required onChange={inputHandler} className=' w-full  bg-transparent border my-4 p-2 md:p-3 xl:p-4 text-lg capitalize rounded-lg' type="text" placeholder='First Name' name='firstName' pattern="[a-zA-Z ]+" minLength={4} />
                                    <input value={formData.lastName} required onChange={inputHandler} className=' w-full  bg-transparent border my-4 p-2 md:p-3 xl:p-4 text-lg capitalize rounded-lg' type="text" placeholder='Last Name' name='lastName' pattern="[a-zA-Z ]+" minLength={4} />
                                </div>
                                <input value={formData.email} required onChange={inputHandler} className=' w-full  bg-transparent border my-4 p-2 md:p-3 xl:p-4 text-lg  lowercase rounded-lg' type="email" placeholder='Your Email' name='email' />

                                <input value={formData.phone} required onChange={inputHandler} className=' w-full  bg-transparent border my-4 p-2 md:p-3 xl:p-4 text-lg capitalize rounded-lg' type="text" placeholder='Your Mobile' name='phone' pattern="[0-9]+" maxLength={10} minLength={10} />

                                <textarea value={formData.message} onChange={inputHandler} name="message" id="" className='w-full  bg-transparent border my-4 p-2 md:p-3 xl:p-4 text-lg capitalize rounded-lg' cols="30" rows="5" placeholder='Your Message'></textarea>
                                <input disabled={buttonStatus} className=' w-full disabled:bg-red-500/50 bgYellowGreen   my-4 p-2 md:p-3 xl:p-4 text-lg capitalize rounded-lg cursor-pointer' type="submit" value={buttonStatus ? buttonStatus : "submit now"} />
                            </div>
                        </form>
                    </div>
                    <div className='py-10 sm:py-16 md:py-20'>
                        <div className='lg:flex justify-between items-start'>
                            {paraData.map((item, index) => {
                                return <div className='my-10 lg:max-w-md xl:max-w-lg' key={index + 1} >
                                    <h2 className='yanoneFont uppercase text-white text-3xl sm:text-4xl md:text-5xl'>{item.heading}</h2>
                                    <p className='mt-5 textLightGray'>{item.text}</p>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className='my_container py-5 md:py-7'>
                    <div className='md:flex flex-row-reverse items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-sm xl:max-w-lg   mx-auto md:mx-0  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={'https://images.unsplash.com/photo-1540111970170-b1c4d4fbadaa?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjAyfHxneW18ZW58MHx8MHx8fDA%3D'} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-sm xl:max-w-md   '   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'>WHY CHOOSE HYPE THE GYM   </span>FRANCHISE?</h2>
                            <p className='mt-5 textLightGray'>Choosing a HYPE THE GYM franchise means aligning with a proven and dynamic fitness brand. We offer a compelling combination of expertise, state-of-the-art facilities, and a supportive community. When you choose HYPE THE GYM, you're choosing a business model with a track record of success and a passionate team committed to your growth.</p>
                        </div>
                    </div>
                    <div className='md:flex items-center  justify-between my-5 md:my-14 xl:my-36'>
                        <div className='max-w-sm md:max-w-sm xl:max-w-lg   mx-auto md:mx-0  rounded-lg'>
                            <img className='w-full h-full object-cover object-center rounded-lg' src={"https://images.unsplash.com/photo-1593079831268-3381b0db4a77?auto=format&fit=crop&q=60&w=500&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGd5bXxlbnwwfHwwfHx8MA%3D%3D"} loading='lazy' alt="" />
                        </div>
                        <div className='my-10 md:my-0  md:max-w-sm xl:max-w-md'   >
                            <h2 className='yanoneFont capitalize text-white text-3xl sm:text-4xl md:text-5xl text-center md:text-start'> <span className='textYellowGreen'> HYPE THE GYM FRANCHISE  </span> BENEFITS FOR YOU</h2>
                            <p className='mt-5 textLightGray'>By joining the HYPE THE GYM franchise family, you gain access to a range of benefits, including comprehensive training, marketing support, and operational guidance. Our established brand and successful business model provide a strong foundation for your franchise. With HYPE THE GYM, you're not just investing in a gym – you're investing in your own path to success in the fitness industry.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </section>
        </>
    )
}

export default Franchise