import React from 'react';
import { PiWhatsappLogoFill } from 'react-icons/pi';
import { BsTelephoneOutboundFill } from 'react-icons/bs';

const Quickcontact = () => {
    return (
        <div className="fixed right-5 xl:right-10 bottom-20 z-50" >
            <div >
                <div className='text-5xl flex flex-col'>
                    <a href="https://api.whatsapp.com/send?phone=+919999501352&text=Hi%2C%20I'm%20interested%20in%20joining%20Hype%20the%20Gym.%20Can%20you%20please%20provide%20me%20with%20information%20on%20membership%20options%20and%20pricing%3F"  ><span className='my-3 bg-white rounded-full p-[3px] animate-bounce inline-block'><PiWhatsappLogoFill className='text-green-500' /></span></a>
                    <a href="tel:+919999501352"> <span className='my-3 bg-white rounded-full p-3 animate-bounce inline-block text-3xl'><BsTelephoneOutboundFill className='text-blue-700 ' /></span></a>
                </div>
            </div>
        </div>
    )
}

export default Quickcontact