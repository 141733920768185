
import './App.css';
import Home from './components/Home/Home';
// import FixedContact from './components/common/FixedContact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Franchise from './components/otherpages/Franchise';
import Location from './components/otherpages/Location';
import { useState } from 'react';
import sideImageContactForm from './images/contactformsideimage.png';
import { FaTimes } from 'react-icons/fa';
import Navbar from './components/common/Navbar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import sendMailHandler from './components/common/sendMail';
import AboutUs from './components/otherpages/AboutUs';
import Quickcontact from './components/common/QuikeContact';

function App() {
  const [isFormOpen, setIsFormOpen] = useState(false);

  const toggleContactForm = () => {
    setIsFormOpen(!isFormOpen);
  };
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phone: '',
  });

  const inputHandler = (e) => {
    const newValue = e.target.value.replace(/\s+/g, " ");
    setFormData({ ...formData, [e.target.name]: newValue });
  }


  const [buttonStatus, setbuttonStatus] = useState("");
  const handleSubmit = (e) => {
    e.preventDefault();
    const message = `New Message from ${formData.firstName}  ${formData.lastName}

Dear Aseem Yadav,
I hope this message finds you well. I wanted to share a message we received from a potential customer interested in our gym. The message is as follows:
First Name: ${formData.firstName} 
Last Name:${formData.lastName}
Phone Number: ${formData.phone}

This individual is interested in our gym and may have inquiries or feedback. We recommend reaching out to them promptly to assist with their needs and provide any information they may require.
     
Best regards,
${formData.firstName}  ${formData.lastName}
    
${formData.phone}
      `
    const userSubject = `New Appointment/Franchise Inquiry from ${formData.firstName} ${formData.lastName}`;
    sendMailHandler(message, userSubject, setbuttonStatus)
    setFormData({
      firstName: '',
      lastName: '',
      phone: '',
    })
  };


  return (
    <Router>
      <Navbar toggleContactForm={toggleContactForm} />
      <Quickcontact />
      <ToastContainer position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored" />
      {/* ====================================================== Contact form Poup --------------------------------------------------------------- */}
      {
        isFormOpen &&
        <section className='fixed top-0 left-0 h-screen w-screen   z-[180]' >
          <div className=' w-full h-full flex items-center justify-center bg-zinc-700/80 '>
            <div className='w-full   max-w-4xl   overflow-hidden    bg-black py-10 md:py-0 mt-16 rounded-xl relative'>
              <div className='absolute right-5 top-3 '>
                <button className="textWhite text-3xl" onClick={toggleContactForm}>
                  <FaTimes />
                </button></div>
              <div className='  h-full  max-w-xs sm:max-w-none  mx-auto md:flex justify-between items-center'>
                <div className='hidden md:block max-w-xs lg:max-w-md '>
                  <img className='h-[400px] lg:h-[450px]    object-cover object-center' src={sideImageContactForm} alt="contactformImage" />
                </div>
                <div className='max-w-md lg:max-w-lg md:ml-0 md:mr-5 w-full    mx-auto  '>
                  <div className='text-center'>
                    <h2 className='capitalize text-4xl   yanoneFont textYellowGreen'>Elevate Your   <span className='textWhite'>Fitness Journey</span></h2>
                    <p>Please Fill Out the Form Below to Get Started</p>
                  </div>
                  <div className='pr-3 md:pr-0  mt-5'>
                    <form onSubmit={handleSubmit} >
                      <div className='md:flex  '>
                        <input value={formData.firstName} required onChange={inputHandler} name='firstName' pattern="[a-zA-Z ]+" minLength={4} className='w-full lg:w- outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg md:mr-3' type="text" placeholder='First Name' />
                        <input value={formData.lastName} required onChange={inputHandler} name='lastName' pattern="[a-zA-Z ]+" minLength={4} className='w-full lg:w- outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg' type="text" placeholder='Last Name' />
                      </div>
                      <input value={formData.phone} required onChange={inputHandler} name='phone' pattern="[0-9]+" maxLength={10} minLength={10} type="text" className='w-full   outline-none bg-zinc-700 my-3 px-3 py-[10px] rounded-lg' placeholder='Your Mobile Number' />
                      <button type="submit" className='py-3 mt-5 w-full bgYellowGreen textWhite rounded-lg disabled:bg-red-500/50' disabled={buttonStatus}>{buttonStatus ? buttonStatus : "Submit Now"}</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      }
      {/* ====================================================== Contact form Poup --------------------------------------------------------------- */}
      <Routes>
        <Route path={'/'} element={<Home toggleContactForm={toggleContactForm} />} />
        <Route path={'/franchise'} element={<Franchise toggleContactForm={toggleContactForm} />} />
        <Route path={'/locations'} element={<Location toggleContactForm={toggleContactForm} />} />
        <Route path={'/aboutus'} element={<AboutUs toggleContactForm={toggleContactForm} />} />
      </Routes>
    </Router>
  );
}

export default App;
