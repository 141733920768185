import React from 'react'
import Navbar from '../common/Navbar'
import HeroSection from './HeroSection';
import OurProgram from './OurProgram';
import OurServices from './OurServices';
import MemberShip from './MemberShip';
import Footer from '../common/Footer'
import Comments from '../comments/Comments';
import JoinCommunitySection from '../Joincommunity/JoinCommunitySection';



const Home = ({ toggleContactForm }) => {


    return (
        <>
            {/* ------------------------------------------------------ Navbar importing start------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------ Navbar importing end------------------------------------------------------------------------------ */}
            {/* ------------------------------------------------------ Hero Section Start here ------------------------------------------------------------------------ */}
            <HeroSection toggleContactForm={toggleContactForm} />
            {/* ----------------------------------------------------------------- Hero Section ends here ------------------------------------------------------------------------ */}
            {/* ---------------------------------------------------------------- Our Programs section start here------------------------- */}
            <OurProgram />
            {/* ---------------------------------------------------------------- Our Programs section ends here------------------------- */}

            {/* --------------------------------------------------------------- Our Services Section start here ------------------------------------------ */}
            <OurServices />
            {/* ---------------------------------------------------------------------- Our Services Section ends here ------------------------------------------ */}

            {/* --------------------------------------------------------- Membership section start here --------------------------------------------------------- */}
            <MemberShip toggleContactForm={toggleContactForm} />
            {/* --------------------------------------------------------- Membership section start here --------------------------------------------------------- */}

            {/* ------------------------------------------------------ What people say about us section start here ------------------------------------------ */}
            {/* <Comments /> */}
            {/* ------------------------------------------------------ What people say about us section start here ------------------------------------------ */}

            {/* ------------------------------------------------------- Join our community section start here -------------------------------------------- */}
            <JoinCommunitySection toggleContactForm={toggleContactForm} />
            {/* ------------------------------------------------------- Join our community section start here -------------------------------------------- */}

            {/* ---------------------------------------------------------- Footer section start here ------------------------------------------------------  */}
            <Footer />
            {/* ---------------------------------------------------------- Footer section start here ------------------------------------------------------  */}
        </ >
    )
}

export default Home