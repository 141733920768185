import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import { BiLeftArrowAlt, BiRightArrowAlt } from 'react-icons/bi'
import "./style.css";
import image1 from '../../images/ourprogams/image1.webp'
import image2 from '../../images/ourprogams/image2.webp'
import image3 from '../../images/ourprogams/image3.webp'
import image4 from '../../images/ourprogams/image4.webp'
import image5 from '../../images/ourprogams/image5.webp'
import image6 from '../../images/ourprogams/image6.webp'
import image7 from '../../images/ourprogams/image7.webp'
import image8 from '../../images/ourprogams/image8.webp'
import image9 from '../../images/ourprogams/image9.webp'
import image10 from '../../images/ourprogams/image10.webp'
import image11 from '../../images/ourprogams/image11.webp'
import image12 from '../../images/ourprogams/image12.webp'
import image13 from '../../images/ourprogams/image13.webp'
import image14 from '../../images/ourprogams/image14.webp'
import image15 from '../../images/ourprogams/image15.webp'

// import required modules
import { Autoplay } from "swiper/modules";

const Carousal = () => {
    const imagesData = [image1, image2, image3, image4, image5, image6, image7, image8, image9, image11, image12, image13, image10, image14, image15];
    const swiperRef = useRef(null);
    const goBack = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev();
        }
    }
    const goahead = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }
    const slideNext = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext();
        }
    }
    useEffect(() => {
        slideNext();
        slideNext();
        slideNext();
        slideNext();
        slideNext();
        slideNext();
    }, [])


    return (
        <>
            <section>
                <div>
                    <div className="ourprogramDiv">
                        <Swiper
                            ref={swiperRef}
                            spaceBetween={30}
                            centeredSlides={true}
                            speed={2500}
                            pagination={{
                                clickable: true,
                            }}
                            loop={true}
                            autoplay={{
                                delay: 1000,
                                disableOnInteraction: false,
                            }}
                            slidesPerView={1}
                            breakpoints={{
                                // Add breakpoints for responsive design
                                992: {
                                    spaceBetween: 80,
                                    slidesPerView: 3,
                                },
                                1024: {
                                    spaceBetween: 50,
                                    slidesPerView: 4.5,
                                },
                                1180: {
                                    spaceBetween: 60,
                                    slidesPerView: 4.5,
                                }
                            }}
                            modules={[Autoplay]}
                            className="mySwiper"
                        >
                            {imagesData.map((item, i) => {
                                return <SwiperSlide key={i + 12}>
                                    <div className='h-72 w-full bg-gray-400  '>
                                        <img src={item} className='w-full h-full object-cover object-center' loading='lazy' alt="gymImages" />
                                    </div>
                                </SwiperSlide>
                            })}

                        </Swiper>
                    </div>
                    <div className='text-2xl flex justify-center    '>
                        <button onClick={goBack} className='p-2 rounded-full  duration-300 hoverYellowGreenColor bg-zinc-800 mr-2'><BiLeftArrowAlt /></button>
                        <button onClick={goahead} className='p-2 rounded-full  duration-300 hoverYellowGreenColor bg-zinc-800'><BiRightArrowAlt /></button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Carousal;