import React from 'react';
import { FaDumbbell } from 'react-icons/fa';
import { BsArrowRight } from 'react-icons/bs'



const MemberShip = ({ toggleContactForm }) => {

    const membershipCardData = [{ text: "Our 3-month membership plan at HYPE THE GYM is perfect for those looking to kickstart their fitness journey. Enjoy full access to our state-of-the-art facilities, group classes, and expert trainers. This option offers flexibility and commitment, allowing you to achieve your goals with ease.", heading: "3 Months Membership" }, { text: "For a more extended commitment to your fitness goals, our 6-month membership offers substantial value. Train and transform your body with access to cutting-edge equipment and motivating classes. It's a great choice for those looking for a balance between flexibility and dedication.", heading: "6 Months Membership" }, { text: " Our 1-year membership is the ultimate choice for those committed to long-term health and fitness. Gain uninterrupted access to HYPE THE GYM, including personalized training, nutrition guidance, and exclusive benefits. With this membership, you're making a solid investment in your well-being.", heading: "1 Year Membership" },]

    return (
        <>
            {/* ----------------------------------------------------------- Membership section start here --------------------------------------------------------- */}
            <section>
                <div className='my_container pt-16 sm:pt-5 md:pt-16 pb-7' id='memberships'>
                    <h2 className='capitalize text-4xl md:text-5xl  text-center yanoneFont'>membership for you</h2>
                    <p className='text-center textGray mt-5 md:mt-7 max-w-4xl mx-auto'>At Hype The Gym, we believe that your fitness journey should be as unique as you are. That's why we offer a range of membership options to cater to your individual goals and lifestyle. Whether you're just starting out or you're a seasoned fitness enthusiast, we have the perfect membership plan for you. Explore our options below and take the first step towards a healthier, stronger, and happier you.</p>
                    <div className='flex flex-wrap justify-between pt-10 md:pt-16 lg:pt-20'>
                        {membershipCardData.map((item, index) => {
                            return <div onClick={toggleContactForm} className='group my-9  cardbackgroundDesign max-w-xs xl:max-w-[355px] lg:mx-0  mx-auto relative hover:-translate-y-10 duration-300'>
                                <div className='pt-7 pb-10 px-5 cardLightDarkBg hover:bg-black/0 duration-300 cursor-pointer h-full'>
                                    <span className='bgYellowGreen inline-block textWhite text-3xl p-1 rounded-md'><FaDumbbell /></span>
                                    <p className='yanoneFont text-2xl md:text-4xl lg:text-5xl my-1'>{item.heading}</p>
                                    <p className='textLightGray mt-5'>{item.text}</p>
                                </div>
                                <div className=' absolute bottom-0 left-5 translate-y-[50%]'>
                                    <button className='flex bgYellowGreen max-w-[50px]  group-hover:max-w-[120px]   overflow-hidden textWhite  pt-1 px-3 text-2xl duration-500'>
                                        <span className='text-sm font-bold   min-w-[150px] flex  translate-x-[-79%]  group-hover:translate-x-0 duration-500 '>Discover More<span className='inline-block ml-3 text-2xl'><BsArrowRight /></span></span>
                                    </button>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
            </section>
            {/* ----------------------------------------------------------- Membership section Ends here --------------------------------------------------------- */}

        </>
    )
}

export default MemberShip

