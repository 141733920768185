import React, { useMemo, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { FaBars, FaTimes } from 'react-icons/fa';
import logo from '../../images/redlogo.svg'
import './common.css';
import { Link, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Navbar = ({ toggleContactForm }) => {
    const [isOpen, setIsOpen] = useState(false);


    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };


    const navigateHandler = (id) => {


        setTimeout(() => {
            const elementToScroll = document.getElementById(id);
            if (elementToScroll) {
                elementToScroll.scrollIntoView({ behavior: 'smooth' });
            }
        }, 100);
    }

    return (
        <>
            <section className='fixed top-0 left-0 w-full z-[190] bg-black  '  >
                <div>
                    <div className='my_container'>
                        <div className='flex items-center justify-between py-2 '>
                            <div  >
                                <Link to={'/'}> <img src={logo} className='w-36' alt="logo" /></Link>
                            </div>
                            <div className='lg:hidden'>
                                {isOpen ? (
                                    <button className="text-white text-2xl" onClick={toggleSidebar}>
                                        <FaTimes />
                                    </button>
                                ) : (
                                    <button className="text-white text-2xl" onClick={toggleSidebar}>
                                        <FaBars />
                                    </button>
                                )}
                            </div>
                            <div className='hidden lg:block'>
                                <ul className='capitalize flex items-center space-x-6'>
                                    <li className='duration-300 font-bold texthover cursor-pointer'><Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("home") }}  >Home</Link></li>
                                    <li className='duration-300 font-bold texthover cursor-pointer'><Link to={'/aboutus'} onClick={toggleSidebar} >about us</Link></li>
                                    <li className='duration-300 font-bold texthover cursor-pointer'><Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("gallery") }}  >Our   Gallery</Link></li>
                                    <li className='duration-300 font-bold texthover cursor-pointer'><Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("ourservices") }}  >our services</Link></li>
                                    <li className='duration-300 font-bold texthover cursor-pointer'><Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("memberships") }}  >memberships</Link></li>

                                    <Link onClick={toggleSidebar} to={'/franchise'}><li className='duration-300 font-bold texthover cursor-pointer'>franchise</li></Link>
                                    <Link onClick={toggleSidebar} to={'/locations'}><li className='duration-300 font-bold texthover cursor-pointer'>locations</li></Link>
                                    <li onClick={toggleContactForm} className='duration-300 font-bold texthover cursor-pointer'><button className='py-2 px-6 border border-white rounded-md borderYellow'>Join Now</button></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <div
                    className={`lg:hidden transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'
                        } fixed inset-y-0 right-0 w-1/2 p-4 bg-black z-[200] `}
                >
                    <button className="text-white mb-4 text-2xl mt-2" onClick={toggleSidebar}>
                        <FaTimes />
                    </button>
                    <div className="flex flex-col space-y-4 duration-300 transition-all capitalize">
                        <Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("home") }} className="duration-300 font-bold texthover cursor-pointer mt-5  ">Home</Link>
                        <Link to={'/aboutus'} onClick={toggleSidebar} className="duration-300 font-bold texthover cursor-pointer mt-5  ">about us</Link>
                        <Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("gallery") }} className="duration-300 font-bold texthover cursor-pointer mt-5  ">OUR GALLERY</Link>
                        <Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("ourservices") }} className="duration-300 font-bold texthover cursor-pointer mt-5  ">our services</Link>
                        <Link to={'/'} onClick={() => { toggleSidebar(); navigateHandler("memberships") }} className="duration-300 font-bold texthover cursor-pointer mt-5  ">memberships</Link>

                        <Link to={'/franchise'} onClick={toggleSidebar} className="duration-300 font-bold texthover cursor-pointer mt-5">franchise</Link>
                        <Link to={'/locations'} onClick={toggleSidebar} className="duration-300 font-bold texthover cursor-pointer mt-5 ">locations</Link>
                        {/* <a href='' className="duration-300 font-bold texthover cursor-pointer mt-5  ">contact us</a> */}
                        <button onClick={() => {
                            toggleSidebar();
                            toggleContactForm();
                        }} className='py-2 px-6 border border-white rounded-md borderYellow'>Join Now</button>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Navbar