import React from 'react';
import { AiFillInstagram, AiFillFacebook, AiFillPhone, AiFillYoutube } from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io'

import logo from '../../images/redlogo.svg'

const Footer = () => {
    return (
        <>
            <section className='border-t border-white pt-5 md:mt-4'>
                <div className="my_container py-10">
                    <div className='lg:flex justify-between items-start'>
                        <div className='flex flex-col lg:max-w-md xl:max-w-lg'>
                            <img src={logo} className='w-36 ' alt="logo" />
                            <div className=''>
                                <p className=' my-7'>Unleash Your Potential: Elevate Your Fitness Journey at Our Gym. Experience latest device, expert running shoes, and a colourful community. Join us today and redefine your limits.</p>
                                <div className='flex space-x-5 justify-start items-center text-4xl mt-5'>
                                    <a href="https://instagram.com/hypethegym?igshid=NjIwNzIyMDk2Mg==" target='_blank'><AiFillInstagram className='cursor-pointer texthover hover:-translate-y-2 duration-300' /></a>
                                    <a href="https://www.facebook.com/HypeTheGym?mibextid=ZbWKwL" target='_blank'><AiFillFacebook className='cursor-pointer texthover hover:-translate-y-2 duration-300' /></a>
                                    <a href="https://api.whatsapp.com/send?phone=+919999501352&text=Hi%2C%20I'm%20interested%20in%20joining%20Hype%20the%20Gym.%20Can%20you%20please%20provide%20me%20with%20information%20on%20membership%20options%20and%20pricing%3F" target="_blank"><IoLogoWhatsapp className='cursor-pointer texthover hover:-translate-y-2 duration-300' /></a>
                                    <a href="tel:+919999501352"><AiFillPhone className='cursor-pointer texthover hover:-translate-y-2 duration-300' /></a>
                                    <a href="https://youtube.com/@hypethegyms?si=WrSvXpiGarbnJQUn"><AiFillYoutube className='cursor-pointer texthover hover:-translate-y-2 duration-300' /></a>
                                </div>
                            </div>
                        </div>
                        <div className='sm:flex items-start justify-between sm:mt-10 lg:mt-0'>
                            <div className='lg:mr-16'>
                                <ul className='my-7 sm:my-0'>
                                    <li className='yanoneFont text-2xl md:text-3xl'>contact </li>
                                    <li className='my-3'>hypethegym2012@gmail.com</li>
                                    <li className='my-3'>+91-9999501352, +91-9555200004</li>
                                </ul>
                            </div>
                            <div>
                                <ul>
                                    <li className='yanoneFont text-2xl md:text-3xl'>info: </li>
                                    <li className='my-3'><span className='inline-block w-36'>Opening Days</span><span>Mon-Sat</span></li>
                                    <li className='my-3'><span className='inline-block w-36'>Timings</span><span>6am to 10pm</span></li>
                                    <li className='my-3'><span className='inline-block w-36'>Sunday</span><span>2pm to 10pm</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='border-t  py-5  '>
                    <p className='text-center'>©copyright @ 2023</p>
                </div>
            </section>
        </>
    )
}

export default Footer

